<template>
    <div class="grid admintext-demo">
        <Toolbar class="toolbar_top">
            <template v-slot:start>
                <button class="p-link" id="btn_back" @click="backToRapport">
                    <i class="pi pi-angle-left"></i>
                </button>
                <div class="my-2">
                    <h3>{{ t('listEvaluations') }}</h3>
                    <h3>{{ titleTotalNumber }}</h3>
                </div>
            </template>
            <template v-slot:end>
                <ul class="block_list">
                    <li class="download_1">
                        <Button icon="pi pi-download" class="button-table mr-2" mode="basic" @click="exportDocuments"
                                label="Import" chooseLabel="Import"/>
                    </li>
                </ul>
            </template>
        </Toolbar>
        <div class="col-12">
            <div class="card">
                <DataTable :value="evaluations" :totalRecords="totalItemsEvaluation"
                           dataKey="id" :paginator="true" :rows="30"
                           :rowHover="true"
                           class="p-datatable-gridlines table-filter-texte"
                           v-model:filters="filters1" filterDisplay="menu" :loading="loadingEvaluation"
                           :filters="filters1"
                           responsiveLayout="scroll"
                           :globalFilterFields="['titleDescription', 'title_rte', 'title_compliance', 'evaluation_status', 'comment', 'evaluation_date', 'nb_actions_plan', 'site_names']"
                           @filter="filterDataTableEvent($event)"
                           @update:filters="handleFilterApplied"
        >
          <template #loading>
            Loading data. Please wait.
          </template>
          <template #header>
            <div class="table-header-container">
              <div class="grid formgrid">

                <div class="field-control col">
                  <label for="theme">{{ t('status') }}</label>
                  <Dropdown v-model="statusValue" :options="listStatus" optionLabel="name"
                            :placeholder="t('select')"/>
                </div>
                <div class="field-control py-4 btn-search">
                  <Button type="button" class="mr-2 mb-2 btn-search" :label="t('search')"
                          icon="pi pi-search"
                          :loading="loading" @click="getEvaluationsReport()"/>
                </div>
                <div class="field-control col">

                </div>
              </div>

            </div>
          </template>
          <template #empty>
            {{ t('NoDataToDisplay') }}
          </template>

          <Column field="titleDescription" :header="t('text')" style="width:10rem"
                  :showFilterMatchModes="false">
            <template #body="{data}">
              <div class="tooltip"
                   v-tooltip.right="'<p><strong>'+t('text')+ ':</strong>'+ data.title_document+'</p><p><strong>Description :</strong> '+
                     replaceAllColorsWithWhite(data.description_document)+'</p>'">
                <p v-html="data.title_document"></p>
                <p v-html="data.description_document" v-if="data.description_document !== 'null'"
                   class="text-light"></p>
              </div>
            </template>
            <template #filter="{filterModel}">
              <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                         :placeholder="t('search')"/>
            </template>
          </Column>
          <Column field="title_rte" :header="t('titleOfCompliance')" style="width:10rem"
                  :showFilterMatchModes="false">
            <template #body="{data}">
              <div v-if="is_rte" class="tooltip"
                   v-tooltip.right="'<p><strong> '+  t('text') +' : </strong>'+data.title_rte+'</p>'">
                <p>{{ data.title_rte }}</p>
              </div>

              <div v-else class="tooltip"
                   v-tooltip.bottom="'<p><strong>'+t('text')+ ':</strong>'+ data.title_section+'</p>'">
                <p v-html="data.title_section"></p>
              </div>
            </template>
            <template #filter="{filterModel}">
              <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                         :placeholder="t('search')"/>
            </template>
          </Column>
          <Column field="title_compliance" :header="t('textOfCompliance')" style="width:10rem"
                  :showFilterMatchModes="false">
            <template #body="{data}">
              <div class="tooltip"
                   v-tooltip.bottom="'<p><strong>'+t('text')+ ':</strong>'+ data.title_compliance+'</p>'">
                <p v-html="data.title_compliance"></p>
              </div>
            </template>
            <template #filter="{filterModel}">
              <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                         :placeholder="t('search')"/>
            </template>
          </Column>
          <Column field="evaluation_status" :header="t('evaluation')" style="width:10rem"
                  :showFilterMatchModes="false">
            <template #body="slotProps">
                          <span style=" vertical-align: middle" class="image-text">
                              <span>
                               {{ slotProps.data.evaluation_status }}
                                </span>
                           </span>
            </template>
            <template #filter="{filterModel}">
              <Dropdown v-model="filterModel.value" :options="listStatusFilter" placeholder="Any"
                        class="p-column-filter"
                        :showClear="true">
                <template #value="slotProps">
                                    <span :class="'customer-badge status-' + slotProps.value"
                                          v-if="slotProps.value">{{ slotProps.value }}
                                    <span style="display: none"> {{ slotProps.value }} </span></span>
                  <span v-else>{{ t('select') }}</span>
                </template>
                <template #option="slotProps">
                                    <span :class="'customer-badge status-' + slotProps.option.label">{{
                                        slotProps.option
                                      }} <span
                                          style="display: none"> {{
                                          slotProps.option
                                        }} </span> </span>
                </template>
              </Dropdown>
            </template>
          </Column>
          <Column field="comment" :header="t('comment')" style="width:10rem"
                  :showFilterMatchModes="false">
            <template #body="slotProps">
                          <span style=" vertical-align: middle" class="image-text">
                              <span>
                               {{ slotProps.data.comment }}
                                </span>
                          </span>
            </template>
            <template #filter="{filterModel}">
              <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                         :placeholder="t('search')"/>
            </template>
          </Column>
          <Column filterField="evaluation_date" :header="t('valuationDate')" style="min-width:8rem"
                  :showFilterMatchModes="false">
            <template #body="slotProps">
                            <span style="margin-left: .5em; vertical-align: middle"
                                  class="image-text"> {{ formatDate(slotProps.data.evaluation_date) }}</span>
            </template>
            <template #filter="{filterModel}">
              <Calendar v-model="filterModel.value" dateFormat="dd/mm/yy" placeholder="dd/mm/yyyy"
                        selectionMode="range"/>
            </template>
          </Column>

          <Column field="nb_actions_plan" :header="t('attachedActionPlan')" style="width:10rem"
                  :showFilterMatchModes="false">
            <template #body="{data}">
              <p>
                {{ data.nb_actions_plan }}
              </p>
            </template>
            <template #filter="{filterModel}">
              <Dropdown v-model="filterModel.value" :options="listAttachConf" placeholder="Any"
                        class="p-column-filter"
                        :showClear="true">
                <template #value="slotProps">
                                    <span :class="'customer-badge status-' + slotProps.value"
                                          v-if="slotProps.value">{{ slotProps.value }}
                                    <span style="display: none"> {{ slotProps.value }} </span></span>
                  <span v-else>{{ t('select') }}</span>
                </template>
                <template #option="slotProps">
                                    <span :class="'customer-badge status-' + slotProps.option.label">{{
                                        slotProps.option
                                      }} <span
                                          style="display: none"> {{
                                          slotProps.option
                                        }} </span> </span>
                </template>
              </Dropdown>
            </template>
          </Column>
          <Column field="site_names" header="Site" style="min-width:7rem"
                  :showFilterMatchModes="false">
            <template #body="{data}">
                              <span>
                                {{ data.site_names }}
                              </span>
            </template>
            <template #filter="{filterModel}">
              <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                         :placeholder="t('search')"/>
            </template>
          </Column>

          <Column style="min-width:4rem">
            <template #body="slotProps">
              <Button icon="pi pi-pencil" class="btn_file_pdf button-table mr-2"
                      @click="dialogEvaluation(slotProps.data)"
                      v-tooltip.top="{value:t('edit'), class: 'editBtn'}"/>
              <!--              <Button icon="pi pi-fw pi-times" class="btn_remove button-table mr-2"-->
              <!--                      @click="deleteCompliance(slotProps.data)"-->
              <!--                      v-tooltip.top="{value:t('delete'), class: 'editBtn'}"/>-->
            </template>
          </Column>
        </DataTable>

        <Dialog v-model:visible="evaluationDialog" :style="{width: '900px'}"
                :modal="true" class="modal_general p-fluid spaceTitle">
          <template #header>
            <span v-html="titleDialogEvaluation"></span>
          </template>
          <TabView class="tabs_texte_admin tabview-custom">
            <TabPanel>
              <template #header>
                <span>{{ t('evaluations') }}</span>
              </template>
              <div class="formgrid grid">
                <div class="field-control col">
                  <label for="Priorite">{{ t('evaluation') }}<span>*</span> </label>
                  <Dropdown v-model="evaluationStatus" :options="valuesEvaluations" optionLabel="name"
                            required="true"
                            :class="{'p-invalid': submitted && !evaluationStatus}"
                            @change="chnageStatus()"/>
                  <small class="p-invalid"
                         v-if="submitted && !evaluationStatus">{{
                      t('statusEvaluationRequired')
                    }}</small>
                </div>
                <div class="field-control col">
                  <label for="titre">{{ t('valuationDate') }} :</label>
                  <Calendar :showIcon="true" :showButtonBar="true" v-model="evaluation_date"
                            dateFormat="dd/mm/yy"></Calendar>
                </div>
              </div>
              <div class="formgrid grid">
                <div class="field-control col" v-if="!verifAdmin">
                  <label for="description">Annotation :</label>
                  <Textarea id="description" v-model="evaluation.annotation" required="true" rows="5"
                            cols="20"
                            disabled/>
                </div>
                <div class="field-control col" v-if="verifAdmin">
                  <label for="description">Annotation :</label>
                  <Textarea id="description" v-model="evaluation.annotation" required="true" rows="5"
                            cols="20"/>
                </div>
                <div class="field-control col">
                  <label for="description">{{ t('comment') }} :</label>
                  <Textarea id="description" v-model="evaluation.comment" required="true" rows="5"
                            cols="20"/>
                </div>
              </div>
            </TabPanel>
            <TabPanel>
              <template #header>
                <span>{{ t('historic') }}</span>
              </template>
              <DataTable :value="historicEvaluation" :paginator="true" :rows="10" :lazy="true"
                         :totalRecords="totalItemsHistEval"
                         responsiveLayout="scroll" @page="onPageHistEval($event.page +1)"
                         class="p-datatable-gridlines table-filter-texte"
                         :loading="loadingHistoricEvaluation">

                <template #empty>
                  {{ t('NoDataToDisplay') }}
                </template>
                <Column field="evaluation" header="Evaluation">
                  <template #body="slotProps">
                    {{ slotProps.data.status }}
                  </template>
                </Column>
                <Column field="ValuedBy" :header="t('ValuedBy')">
                  <template #body="slotProps">
                    {{ slotProps.data.username }}
                  </template>
                </Column>
                <Column field="representative.name" :header="t('comment')">
                  <template #body="slotProps">
                    {{ slotProps.data.comment }}
                  </template>
                </Column>
                <Column field="representative.name" :header="t('valuationDate')">
                  <template #body="slotProps">
                    {{ formatDate(slotProps.data.evaluation_date) }}
                  </template>
                </Column>
                <Column field="representative.name" :header="t('updatedAt')">
                  <template #body="slotProps">
                    {{ formatDate(slotProps.data.updated_at) }}
                  </template>
                </Column>

              </DataTable>
            </TabPanel>
            <TabPanel>
              <template #header>
                <span>{{ t('actionPlan') }}</span>
              </template>
              <DataTable :value="actionPlanList" :paginator="true" :rows="30" :lazy="true"
                         :totalRecords="totalItemsAP"
                         responsiveLayout="scroll" @page="onPageActionPlan($event.page +1)"
                         class="p-datatable-gridlines table-filter-texte" :loading="loadingAPList">
                <template #empty>
                  {{ t('NoDataToDisplay') }}
                </template>
                <template #header>
                  <div class="table-header-container" style="display:flex">
                    <Button :label="t('attachActionPlan')" icon="pi pi-paperclip"
                            class="p-button p-component add-text mr-2"
                            @click="modalAttachAP"/>
                    <Button :label="t('addActionPlan')" icon="pi pi-plus"
                            class="p-button p-component add-text mr-2"
                            @click="openModalAP"/>
                  </div>
                </template>
                <Column field="ValuedBy" :header="t('title')">
                  <template #body="slotProps">
                    {{ slotProps.data.title }}
                  </template>
                </Column>
                <Column style="min-width:4rem">
                  <template #body="slotProps">
                    <Button icon="pi pi-pencil" class="btn_file_pdf button-table mr-2"
                            @click="modalEditPAEval(slotProps.data)"
                            v-tooltip.top="{value:t('edit'), class: 'editBtn'}"/>
                    <Button icon="pi pi-fw pi-times" class="btn_remove button-table mr-2"
                            @click="deleteAPEval(slotProps.data)"
                            v-tooltip.top="{value:t('delete'), class: 'editBtn'}"
                            v-if="role_user_site != 1"/>
                  </template>
                </Column>
              </DataTable>
            </TabPanel>

          </TabView>
          <template #footer>
            <Button :label="t('cancel')" icon="pi pi-times" class="p-button-text event_remove"
                    @click="hideDialogEval"/>
            <Button :label="t('save')" icon="pi pi-check" class="p-button-text next_btn"
                    @click="saveEvaluation" :disabled="btnEvaluationSubmit"
                    v-if="role_user_site != 1 && role_user_site != 2"/>
          </template>
        </Dialog>
        <Dialog v-model:visible="PADialog" :style="{width: '900px'}"
                :modal="true" class="modal_general p-fluid spaceTitle" header="Identification">
          <div class="formgrid grid">
            <div class="field-control col">

              <label for="titre">{{ t('title') }}<span>*</span> </label>
              <InputText id="name" v-model="planAction.title" autofocus
                         :class="{'p-invalid': submittedPA && !planAction.title}"/>
              <small class="p-invalid" v-if="submittedPA && !planAction.title">{{
                  t('titleRequired')
                }}</small>
            </div>
            <div class="field-control col">
              <label for="titre">{{ t('reference') }} </label>
              <InputText id="name" v-model="planAction.reference" autofocus/>
            </div>
          </div>

          <div class="formgrid grid">
            <div class="field-control col">
              <label for="statut">{{ t('subDomain') }}</label>
              <TreeSelect v-model="valueSubDomain" :options="tabCategories" selectionMode="single"
                          placeholder="Select Item"
                          @node-select="selectNode"/>
            </div>
            <div class="field-control col">
              <label for="Priorite">{{ t('priority') }} </label>
              <Dropdown v-model="priority" :options="valuesPriorities" optionLabel="name" required="true"
                        @change="chnageStatus($event)"/>
            </div>
          </div>

          <div class="formgrid grid">
            <div class="field-control col">
              <label for="Priorite">{{ t('apPilot') }} </label>
              <Dropdown v-model="apPilot" :options="listUsers" optionLabel="user_name"
                        @change="chnageStatus($event)"/>
            </div>

            <div class="field-control col">
              <label for="Priorite">{{ t('responsibleAP') }}<span>*</span> </label>
              <Dropdown v-model="responsibleAP" :options="listUsers" optionLabel="user_name"
                        required="true"
                        :class="{'p-invalid': submittedPA && !responsibleAP}"/>
              <small class="p-invalid" v-if="submittedPA && !responsibleAP">{{
                  t('responsibleAPRequired')
                }}</small>
            </div>
          </div>

          <div class="formgrid grid">
            <div class="field-control col">
              <label for="Priorite">{{ t('typeProgresion') }} </label>
              <Dropdown v-model="typeProgress" :options="listTypeProgression" optionLabel="value"
                        required="true"/>
            </div>

            <div class="field-control col">
              <label for="Priorite">{{ t('rateProgress') }} </label>
              <Dropdown v-model="rateProgress" :options="listRateProgression" optionLabel="label"/>
            </div>

          </div>
          <div class="formgrid grid">
            <div class="field-control col">
              <label for="titre">{{ t('startDate') }} <span>*</span></label>
              <Calendar :showIcon="true" :showButtonBar="true" v-model="start_date"
                        dateFormat="dd/mm/yy"
                        :class="{'p-invalid': submittedPA && !start_date}"></Calendar>
              <small class="p-invalid" v-if="submittedPA && !start_date">{{
                  t('startDateRequired')
                }}</small>
            </div>

            <div class="field-control col">
              <label for="titre">{{ t('endDate') }} <span>*</span></label>
              <Calendar :showIcon="true" :showButtonBar="true" v-model="end_date"
                        dateFormat="dd/mm/yy" :class="{'p-invalid': submittedPA && !end_date}"
                        @update:modelValue="changeEndDate($event)"></Calendar>
              <small class="p-invalid" v-if="submittedPA && !end_date">{{ t('endDateRequired') }}</small>
            </div>

            <div class="field-control col">
              <label for="titre">{{ t('revisedEndDate') }} <span>*</span></label>
              <Calendar :showIcon="true" :showButtonBar="true" v-model="revised_end_date"
                        dateFormat="dd/mm/yy"
                        :class="{'p-invalid': submittedPA && !revised_end_date}"></Calendar>
              <small class="p-invalid"
                     v-if="submittedPA && !revised_end_date">{{ t('revisedEndDateRequired') }}</small>
            </div>
          </div>


          <div class="formgrid grid">
            <div class="field-control col">
              <div class="field-radiobutton pt-4">
                <Checkbox id="checkOption1" name="option" :binary="true"
                          v-model="planAction.reminder_fixed"/>
                <label for="confidentiel">{{ t('fixedReminder') }}</label>
              </div>
            </div>

            <div class="field-control col">
              <label for="titre">{{ t('nbDaysBeforeAPDeadline') }} </label>
              <InputNumber id="name" v-model="planAction.reminder_before_deadline" autofocus/>
            </div>
          </div>

          <div class="formgrid grid">
            <div class="field-control col">
              <div class="field-radiobutton pt-4">
                <Checkbox id="checkOption1" name="option" :binary="true"
                          v-model="planAction.reminder_periodic"/>
                <label for="confidentiel">{{ t('periodicRelaunch') }}</label>
              </div>
            </div>

            <div class="field-control col">
              <label for="titre">{{ t('periodicReminderTime') }} </label>
              <InputNumber id="name" v-model="planAction.periodic_reminder_time" autofocus/>
            </div>
          </div>

          <div class="formgrid grid">
            <div class="field-control col">
              <label for="description">{{ t('divers') }} </label>
              <Textarea id="description" v-model="planAction.others" required="true" rows="5" cols="20"/>
            </div>
          </div>

          <template #footer>

            <Button :label="t('cancel')" icon="pi pi-times" class="p-button-text event_remove"
                    @click="hideDialogPA"/>


            <Button :label="t('save')" icon="pi pi-check" class="p-button-text next_btn"
                    @click="savePA()" v-if="role_user_site != 1"/>

          </template>

        </Dialog>
        <Dialog v-model:visible="PAEvalEditDialog" :style="{width: '900px'}"
                :modal="true" class="modal_general p-fluid spaceTitle" :header="t('listOfActionsPlans')">
          <TabView class="tabs_texte_admin tabview-custom">
            <TabPanel>
              <template #header>
                <span>Identification</span>
              </template>
              <div class="formgrid grid">
                <div class="field-control col">
                  <label for="titre">{{ t('title') }}<span>*</span> </label>
                  <InputText id="name" v-model="planAction.title" autofocus
                             :class="{'p-invalid': submittedPA && !planAction.title}"/>
                  <small class="p-invalid"
                         v-if="submittedPA && !planAction.title">{{ t('titleRequired') }}</small>
                </div>
                <div class="field-control col">
                </div>
              </div>
              <div class="formgrid grid">
                <div class="field-control col">
                  <label for="titre">{{ t('reference') }} </label>
                  <InputText id="name" v-model="planAction.reference" autofocus/>
                </div>
                <div class="field-control col">
                  <label for="statut">{{ t('subDomain') }}</label>
                  <TreeSelect v-model="valueSubDomain" :options="tabCategories" selectionMode="single"
                              placeholder="Select Item"
                              @node-select="selectNode"/>
                </div>
              </div>
              <div class="formgrid grid">
                <div class="field-control col">
                  <label>{{ t('divers') }} </label>
                  <Textarea id="description" v-model="planAction.others"/>
                </div>
                <div class="field-control col">
                  <label for="Priorite">{{ t('priority') }} </label>
                  <Dropdown v-model="priority" :options="valuesPriorities" optionLabel="name"/>
                </div>
              </div>
              <div class="formgrid grid">
                <div class="field-control col">
                  <label for="titre">{{ t('process') }} </label>
                  <InputText id="name" v-model="planAction.process"
                  />
                </div>
                <div class="field-control col">
                  <label for="titre">{{ t('risksopportunities') }} </label>
                  <InputText id="name" v-model="planAction.risks_opportunities"
                  />
                </div>
              </div>
            </TabPanel>
            <TabPanel>
              <template #header>
                <span>Pilotage</span>
              </template>
              <div class="formgrid grid">
                <div class="field-control col">
                  <label for="titre">{{ t('startDate') }} <span>*</span></label>
                  <Calendar :showIcon="true" :showButtonBar="true" v-model="start_date"
                            dateFormat="dd/mm/yy"
                            :class="{'p-invalid': submittedPA && !start_date}"></Calendar>
                  <small class="p-invalid" v-if="submittedPA && !start_date">{{
                      t('startDateRequired')
                    }}</small>
                </div>
                <div class="field-control col">
                  <label for="titre">{{ t('endDate') }} <span>*</span></label>
                  <Calendar :showIcon="true" :showButtonBar="true" v-model="end_date"
                            dateFormat="dd/mm/yy" :class="{'p-invalid': submittedPA && !end_date}"
                            @update:modelValue="changeEndDate($event)"></Calendar>
                  <small class="p-invalid" v-if="submittedPA && !end_date">{{
                      t('endDateRequired')
                    }}</small>
                </div>
                <div class="field-control col">
                  <label for="titre">{{ t('revisedEndDate') }} <span>*</span></label>
                  <Calendar :showIcon="true" :showButtonBar="true" v-model="revised_end_date"
                            dateFormat="dd/mm/yy"
                            :class="{'p-invalid': submittedPA && !revised_end_date}"></Calendar>
                  <small class="p-invalid" v-if="submittedPA && !revised_end_date">{{
                      t('revisedEndDateRequired')
                    }}</small>
                </div>
              </div>
              <div class="formgrid grid">
                <div class="field-control col">
                  <label for="Priorite">{{ t('apPilot') }} </label>
                  <Dropdown v-model="apPilot" :options="listUsers" optionLabel="user_name"/>
                </div>
                <div class="field-control col">
                  <label for="Priorite">{{ t('responsibleAP') }}<span>*</span> </label>
                  <Dropdown v-model="responsibleAP" :options="listUsers" optionLabel="user_name"
                            required="true"
                            :class="{'p-invalid': submittedPA && !responsibleAP}"/>
                  <small class="p-invalid"
                         v-if="submittedPA && !responsibleAP">{{ t('responsibleAPRequired') }}</small>
                </div>
              </div>
              <div class="formgrid grid">
                <div class="field-control col">
                  <label for="Priorite">{{ t('typeProgresion') }} </label>
                  <Dropdown v-model="typeProgress" :options="listTypeProgression" optionLabel="value"
                            required="true"/>
                </div>
                <div class="field-control col">
                  <label for="Priorite">{{ t('rateProgress') }} </label>
                  <Dropdown v-model="rateProgress" :options="listRateProgression"
                            optionLabel="label"/>
                </div>
              </div>
              <div class="formgrid grid">
                <div class="field-control col">
                  <div class="field-radiobutton pt-4">
                    <Checkbox id="checkOption1" name="option" :binary="true"
                              v-model="planAction.reminder_fixed"/>
                    <label for="confidentiel">{{ t('fixedReminder') }}</label>
                  </div>
                </div>
                <div class="field-control col">
                  <label for="titre">{{ t('nbDaysBeforeAPDeadline') }} </label>
                  <InputNumber id="name" v-model="planAction.reminder_before_deadline" autofocus/>
                </div>
              </div>
              <div class="formgrid grid">
                <div class="field-control col">
                  <div class="field-radiobutton pt-4">
                    <Checkbox id="checkOption1" name="option" :binary="true"
                              v-model="planAction.reminder_periodic"/>
                    <label for="confidentiel">{{ t('periodicRelaunch') }}</label>
                  </div>
                </div>
                <div class="field-control col">
                  <label for="titre">{{ t('periodicReminderTime') }} </label>
                  <InputNumber id="name" v-model="planAction.periodic_reminder_time" autofocus/>
                </div>
              </div>
            </TabPanel>
            <TabPanel>
              <template #header>
                <span>{{ t('measureEffectiveness') }}</span>
              </template>
              <div class="formgrid grid">
                <div class="field-control col">
                  <label>{{ t('verificationType') }} </label>
                  <Textarea id="description" v-model="planAction.verification_type"/>
                </div>
                <div class="field-control col">
                  <label>{{ t('comment') }} </label>
                  <Textarea id="description" v-model="planAction.comment"/>
                </div>
              </div>
              <div class="formgrid grid">
                <div class="field-control col">
                  <label for="titre">{{ t('verificationDate') }} <span>*</span></label>
                  <Calendar :showIcon="true" :showButtonBar="true" v-model="verification_date"
                            dateFormat="dd/mm/yy">
                  </Calendar>
                </div>
                <div class="field-control col">
                  <label for="Priorite">{{ t('responsibleVerification') }} </label>
                  <Dropdown v-model="verifier_user" :options="listUsers" optionLabel="user_name"/>
                </div>
              </div>
            </TabPanel>
            <TabPanel>
              <template #header>
                <span>{{ t('resources') }}</span>
              </template>

              <div class="formgrid grid">
                <div class="field-control col">
                  <label>{{ t('financialRsources') }} </label>
                  <Textarea id="description" v-model="planAction.financial_resources"/>
                </div>

                <div class="field-control col">
                  <label>{{ t('operationalResource') }} </label>
                  <Textarea id="description" v-model="planAction.operational_resource"/>
                </div>
              </div>
              <div class="formgrid grid">
                <div class="field-control col">
                  <label>{{ t('technologicalResources') }} </label>
                  <Textarea id="description" v-model="planAction.technological_resources"/>
                </div>

                <div class="field-control col">
                  <label>{{ t('humanResources') }} </label>
                  <Textarea id="description" v-model="planAction.humain_resources"/>
                </div>
              </div>

            </TabPanel>
          </TabView>
          <template #footer>
            <Button :label="t('cancel')" icon="pi pi-times" class="p-button-text event_remove"
                    @click="hideDialogEditPAEval"/>
            <Button :label="t('save')" icon="pi pi-check" class="p-button-text next_btn"
                     @click="savePAEdit()" v-if="role_user_site != 1"/>
          </template>
        </Dialog>
        <Dialog v-model:visible="attachPADialog" :style="{width: '900px'}"
                :modal="true" class="modal_general p-fluid spaceTitle" :header="t('listOfActionsPlans')">
          <DataTable :value="actionPlanListAttach" :lazy="true" :totalRecords="totalItemsAPAttach"
                     @page="onPageActionPlanAttach($event.page +1)" dataKey="id" :paginator="true" :rows="30"
                     :rowHover="true"
                     class="p-datatable-gridlines table-filter-texte"
                     v-model:filters="filters2" filterDisplay="menu" :loading="loadingAPAttach"
                     :filters="filters2"
                     responsiveLayout="scroll"
                     :globalFilterFields="['title','start_date','end_date','revised_end_date','user_pilot','user_in_charge','rate_progress','priority']"
                     @filter="filterDataTableAttach($event)"
                     v-model:selection="selectedAPAttach">
            <template #loading>
              Loading data. Please wait.
            </template>
            <!--            <template #header>-->
            <!--              <div class="table-header-container">-->
            <!--                <Button :label="t('addActionPlan')" icon="pi pi-plus" class="add-text p-button-success mr-2"-->
            <!--                        @click="openModalAP()"/>-->
            <!--              </div>-->
            <!--            </template>-->
            <template #empty>
              {{ t('NoDataToDisplay') }}
            </template>
            <Column selectionMode="multiple"
                    style="width: 0.2rem;text-align: center !important;padding: 0 !important;"></Column>
            <Column field="name" :header="t('text')" style="width:10rem" filterField="title"
                    :showFilterMatchModes="false">
              <template #body="{data}">
                <div class="tooltip"
                     v-tooltip.right="'<p><strong>'+t('text')+ ':</strong>'+ data.title+'</p>'">
                  <p>{{ data.title }}</p>

                </div>

              </template>
              <template #filter="{filterModel}">
                <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                           :placeholder="t('searchByName')"/>
              </template>
            </Column>
            <Column :header="t('startDate')" style="min-width:8rem" filterField="start_date"
                    :showFilterMatchModes="false">
              <template #body="{data}">
                <span style="margin-left: .5em; vertical-align: middle"
                      class="image-text"> {{ formatDate(data.start_date) }}</span>
              </template>
              <template #filter="{filterModel}">
                <Calendar v-model="filterModel.value" dateFormat="dd/mm/yy" placeholder="dd/mm/yyyy"/>
              </template>
            </Column>
            <Column :header="t('endDate')" style="min-width:8rem" filterField="end_date"
                    :showFilterMatchModes="false">
              <template #body="{data}">
                <span style="margin-left: .5em; vertical-align: middle"
                      class="image-text"> {{ formatDate(data.end_date) }}</span>
              </template>
              <template #filter="{filterModel}">
                <Calendar v-model="filterModel.value" dateFormat="dd/mm/yy" placeholder="dd/mm/yyyy"/>
              </template>
            </Column>
            <Column :header="t('revisedEndDate')" style="min-width:8rem" filterField="revised_end_date"
                    :showFilterMatchModes="false">
              <template #body="{data}">
                <span style="margin-left: .5em; vertical-align: middle"
                      class="image-text"> {{ formatDate(data.revised_end_date) }}</span>
              </template>
              <template #filter="{filterModel}">
                <Calendar v-model="filterModel.value" dateFormat="dd/mm/yy" placeholder="dd/mm/yyyy"/>
              </template>
            </Column>
            <Column field="name" :header="t('apPilot')" style="width:10rem" filterField="user_pilot"
                    :showFilterMatchModes="false">
              <template #body="{data}">
                <p>{{ data?.user_pilot?.firstname }} {{ data?.user_pilot?.lastname }}</p>
              </template>
              <template #filter="{filterModel}">
                <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                           :placeholder="t('searchByName')"/>
              </template>
            </Column>
            <Column field="name" :header="t('responsibleAP')" style="width:10rem"
                    filterField="user_in_charge"
                    :showFilterMatchModes="false">
              <template #body="{data}">
                <p>{{ data.user_in_charge?.firstname }} {{ data.user_in_charge?.lastname }}</p>
              </template>
              <template #filter="{filterModel}">
                <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                           :placeholder="t('searchByName')"/>
              </template>
            </Column>
            <Column field="name" :header="t('tasks')" style="width:10rem">
              <template #body="{data}">
                {{ data?.action_plan_tasks?.length }}
              </template>
            </Column>
          </DataTable>
          <template #footer>
            <Button :label="t('cancel')" icon="pi pi-times" class="p-button-text event_remove"
                    @click="hideDialogAttachPA"/>
            <Button :label="t('save')" icon="pi pi-check" class="p-button-text next_btn"
                   :disabled="disableAttachPA" @click="saveAttachPA" v-if="role_user_site != 1"/>
          </template>
        </Dialog>
      </div>
    </div>
  </div>
  <ConfirmDialog :breakpoints="{'960px': '75vw', '640px': '100vw'}" :style="{width: '50vw'}"></ConfirmDialog>
  <Toast/>
</template>

<script>
import {usePrimeVue} from "primevue/config";
import {useStore} from "vuex";
import {Actions} from "@/store/enums/StoreEnums";
import {computed} from "vue";
import {useToast} from "primevue/usetoast";
import moment from "moment-timezone";
import {FilterMatchMode} from "primevue/api";

export default {
  name: "Evaluation",
  data() {
    return {
      evaluations: [],
      totalItemsEvaluation: 0,
      statusValue: '',
      listStatus: [{id: null, name: ''}],
      loadingEvaluation: false,
      loading: false,
      titleDialogEvaluation: '',
      evaluationDialog: false,
      evaluationStatus: '',
      valuesEvaluations: [],
      submitted: false,
      evaluation_date: '',
      verifAdmin: true,
      evaluation: {},
      totalItemsHistEval: '',
      historicEvaluation: [],
      loadingHistoricEvaluation: false,
      submittedEval: false,
      actionPlanList: [],
      totalItemsAP: 0,
      loadingAPList: false,
      PAEvalEditDialog: false,
      attachPADialog: false,
      planAction: {},
      submittedPA: false,
      tabCategories: [],
      valueSubDomain: '',
      priority: '',
      valuesPriorities: [{id: 1, name: 'Mineure', value: '78'}, {id: 2, name: 'Moyenne', value: '79'}, {
        id: 3,
        name: 'Majeure',
        value: '80'
      }, {
        id: 0,
        name: 'Aucune',
        value: '228'
      },],
      start_date: '',
      end_date: '',
      revised_end_date: '',
      listUsers: [],
      responsibleAP: '',
      apPilot: '',
      listRateProgression: [
        {id: 1, value: 0, label: '0%',},
        {id: 2, value: 20, label: '20%'},
        {id: 3, value: 40, label: '40%'},
        {id: 4, value: 60, label: '60%'},
        {id: 5, value: 80, label: '80%'},
        {id: 6, value: 100, label: '100%'}],
      rateProgress: {id: 1, value: 0, label: '0%'},

      listTypeProgression: [
                {id: 1, value: 'Par le chargé du plan d\'actions'},
                {id: 1, value: 'Avancement des tâches'},
            ],
            typeProgress: {id: 1, value: 'Par le chargé du plan d\'actions'},
            verification_date: '',
            verifier_user: '',
            actionPlanListAttach: [],
            totalItemsAPAttach: 0,
            filters2: null,
            nbPageActionPlanAttach: 1,
            loadingAPAttach: false,
            PADialog: false,
            selectedAPAttach: null,
            listSiteByEntity: [],
            btnEvaluationSubmit: false,
            listSites: [],
            role_user_site: false,
            site_id_in_modal: null,
            filters1: null,
            listStatusFilter: [],
            listAttachConf: ['Oui', 'Non'],
            titleTotalNumber: '',
            titleDescription_filter: '',
            title_rte_filter: '',
            title_compliance_filter: '',
            evaluation_status_filter: '',
            comment_filter: '',
            evaluation_date_filter: '',
            nb_actions_plan_filter: '',
            site_names_filter: '',
            disableAttachPA: false,
    }
  },
  setup() {
    const primeVue = usePrimeVue();
    const store = useStore();
    const id_site = computed(() => {
      return store.getters.currentSite.id
    });
    const toast = useToast();
    const user = computed(() => {
      return store.getters.currentUser
    });
    const is_rte = computed(() => {
      return store.getters.isRte
    });
    const list_site = computed(() => {
      return store.getters.getListSites
    })
    return {t: primeVue.config.StoreTranslation.t, store, id_site, toast, user, is_rte, list_site}
  },
  watch: {
    user() {
      if (this.user?.role_user && this.user.role_user[0] !== 'ROLE_ADMIN') {
        this.verifAdmin = false
      }
      if (this.user?.application_master) {
        this.verifSuperAdmin = true
      }
      this.user_id = this.user?.id
      this.getUserSiteRole()
    },
    list_site(value) {
      if (value) {
        this.listSites = value
        this.getEvaluationsReport()
      }
    }
  },
  async mounted() {
    this.loading = true
    this.loadingEvaluation = true
    // await this.getCurrentSites()
    this.listSites = this.list_site
    await this.getStatusEvaluation();
    if (this.list_site)
      await this.getEvaluationsReport();
    await this.getActionPlansInit(1, 'attach');
    await this.getUsersAP()
    this.user_id = this.user?.id
    if (this.id_site && this.user_id) {
      this.getUserSiteRole()
    }
  },
  async created() {
    this.initFilters1();
  },
  methods: {
    backToRapport() {
      this.$router.push('/rapport');
    },
    getEvaluationsReport() {
      this.loadingEvaluation = true
      this.loading = true
      let sites = this.listSites.length > 0 ? this.listSites : [this.id_site]
      return this.store.dispatch(Actions.EVALUATION_REPORT, {
        status: this.statusValue.id,
        site: sites,
        join_AP: 'yes',
        customHeader: true
      }).then(data => {
        this.loadingEvaluation = false
        data.forEach(element => {
          element['titleDescription'] = element['title_document'] + '' + element['description_document']
          element['nb_actions_plan'] = element['nb_actions_plan'] > 0 ? 'Oui' : 'Non'
          element['site_names'] = element['site_path']
          element['evaluation_date'] = element['evaluation_date'] ? new Date(element['evaluation_date']) : null
          // element['evaluation_date'] =  new Date(element['evaluation_date'])

          if (!this.listStatus.find(item => item.id === element.evaluation_status_id)) {
            this.listStatus.push({id: element.evaluation_status_id, name: element.evaluation_status})
            this.listStatusFilter.push(element.evaluation_status)

          }
        })
        this.evaluations = data
        this.totalItemsEvaluation = data.length
        this.loading = false
      })
    },
    formatDate(value) {
      if (value) {
        return new Date(value).toLocaleDateString('fr-FR', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        });
      } else return ' '
    },
    exportDocuments() {
      let user = '/api/users/' + this.user_id
      let sites = this.listSites.length > 0 ? this.listSites : [this.id_site]
      let evaluation_date_end = null
      let evaluation_date_start = null
      if (this.evaluation_date_filter) {
        evaluation_date_start = this.evaluation_date_filter[0]
        evaluation_date_end = this.evaluation_date_filter[1] ?? this.addEndOfDayTime(moment(this.evaluation_date_filter[0]).format('yy-MM-DD'))
      }

      this.store.dispatch(Actions.EXPORTS_POST, {
        user: user,
        model: "evaluationReportExport",
        params: {
          site_id: this.id_site,
          status: this.statusValue.id,
          sites: sites,
          titleDescription: this.titleDescription_filter,
          title_rte: this.title_rte_filter,
          title_compliance: this.title_compliance_filter,
          evaluation_status: this.evaluation_status_filter,
          comment: this.comment_filter,
          evaluation_date_start: evaluation_date_start,
          evaluation_date_end: evaluation_date_end,
          nb_actions_plan: this.nb_actions_plan_filter,
          site_names: this.site_names_filter,
        },
        site_id: parseInt(this.id_site),
      }).then(() => {
        this.toast.add({
          severity: 'success',
          detail: this.t('exportWasSuccessfullySent'),
          life: 8000
        });
      })
    },
    dialogEvaluation(dataComp) {
      this.site_id_in_modal = dataComp.site_id
      this.getUserSiteRole()
      this.titleDialogEvaluation = dataComp.title_section + '\n' + dataComp.title_compliance
      this.evaluationDialog = true
      this.idEvaluation = dataComp.id
      this.evaluation.id = dataComp.id
      this.evaluationStatus = this.valuesEvaluations ? this.valuesEvaluations.find(element =>
          element.id == dataComp.evaluation_status_id
      ) : ''

      this.evaluation_date = ''
      if (dataComp.evaluation_date) {
        this.evaluation_date = moment(dataComp.evaluation_date).format('DD/MM/yy');
      }
      this.evaluation.annotation = dataComp.annotation;
      this.evaluation.comment = dataComp.comment;
      this.getHistoricEvaluation(dataComp.id);
      this.onPageActionPlan(1)

    },
    getDetailsEvaluation(idEval) {
      this.btnEvaluationSubmit = true
      this.store.dispatch(Actions.GET_EVALUATION_BY_ID, {
        'idEval': idEval,
        'idSite': this.id_site,
        'customHeader': false
      }).then(data => {
        this.btnEvaluationSubmit = false
        this.evaluationStatus = this.valuesEvaluations.find(element =>
            element.id == data.status.id
        )
        if (moment(data?.evaluation_date).isValid()) {
          this.evaluation_date = new Date(moment(data.evaluation_date).format('yy-MM-DD'))
        }
        this.evaluation.annotation = data.annotation;
        this.evaluation.comment = data.comment;
        this.evaluation.id = this.idEvaluation
        this.evaluation.annotation_compliance = data.compliance.annotation_compliance
        this.evaluation.live_status = data.live_status
      }).catch(() => {
      })
    },
    onPageActionPlan(page) {
      this.nbPageActionPlan = page
      this.getActionPlans(page);
    },
    hideDialogEval() {
      this.evaluationDialog = false
    },
    chnageStatus() {
      this.evaluation_date = moment().format('DD/MM/yy');
    },
    saveEvaluation() {
      this.submittedEval = true
      if (this.evaluationStatus) {
        if (this.evaluation_date === moment().format('DD/MM/yy')) {
          this.evaluation.evaluation_date = moment().format()
        } else if (moment(this.evaluation_date).isValid()) {
          this.evaluation.evaluation_date = moment(this.evaluation_date).format()
        }
        this.evaluation['status'] = '/api/evaluation_statuses/' + this.evaluationStatus.id
        this.evaluation.user = 'api/users/' + this.user_id
        this.btnEvaluationSubmit = true
        this.store.dispatch(Actions.PUT_EVALUATION, this.evaluation).then(async () => {
          this.toast.add({
            severity: 'success',
            detail: this.t('evaluationHasSuccessfullyModified'),
            life: 3000
          });
          this.evaluationDialog = false

          this.submittedEval = false
          this.btnEvaluationSubmit = false
          this.getEvaluationsReport()
        }).catch(() => {
        })
      }
    },
    getHistoricEvaluation(idEval) {
      this.loadingHistoricEvaluation = true
      this.store.dispatch(Actions.HISTORIC_EVALUATION, {
        idEvaluation: idEval,
        'page': this.pageHistEval,
        'customHeader': true,
        'limit': 10,
      }).then(data => {
        if (data) {
          this.loadingHistoricEvaluation = false
          this.historicEvaluation = data['hydra:member']
          this.totalItemsHistEval = data['hydra:totalItems']
        }
      })
    },
    onPageHistEval(nbPage) {
      this.nbPageHistoric = nbPage
      this.getHistoricEvaluation(nbPage, this.idEvaluation)
    },
    getStatusEvaluation() {
      return this.store.dispatch(Actions.GET_STATUS_EVALUATION, {'customHeader': false}).then(data => {
        this.valuesEvaluations = data
        const idsToKeep = new Set([47, 299, 48, 300, 49, 50, 51, 52, 310, 311, 312]);
        this.valuesEvaluations = this.valuesEvaluations.filter(item => idsToKeep.has(item.id));
      }).catch(() => {
      })
    },
    getActionPlans(page) {
      this.loadingAPList = true
      this.store.dispatch(Actions.GET_ACTION_PLAN, {
        page: page,
        'customHeader': true,
        'evaluation': this.idEvaluation
      }).then(data => {
        if (data) {
          this.loadingAPList = false
          this.actionPlanList = data['hydra:member']
          this.totalItemsAP = data['hydra:totalItems']
        }
      })
    },
    modalAttachAP() {
      this.attachPADialog = true
      this.getActionPlansInit(1, 'attach')
    },
    getActionPlansInit(page, type) {
      this.loadingAP = true
      this.loadingAPAttach = true
      this.actionPlanListAttach = []
      this.totalItemsAPAttach = 0
      let rate_progress_start
      if (this.rateProgressFilter) {
        rate_progress_start = this.rateProgressFilter[0]
      }
      let rate_progress_end
      if (this.rateProgressFilter) {
        rate_progress_end = this.rateProgressFilter[1]
      }
      let startDate
      if (this.startDateFilter) {
        startDate = moment(this.startDateFilter).format('yy-MM-DD')
      }
      let endDate
      if (this.endDateFilter) {
        endDate = moment(this.endDateFilter).format('yy-MM-DD')
      }
      let revisedEndDate
      if (this.revisedEndDateFilter) {
        revisedEndDate = moment(this.revisedEndDateFilter).format('yy-MM-DD')
      }
      if (this.id_site !== 'null') {
        return this.store.dispatch(Actions.GET_ACTION_PLAN, {
          page: page,
          'customHeader': true,
          'site': this.id_site,
          'title': this.titleFilter,
          'start_date': startDate,
          'end_date': endDate,
          'revised_end_date': revisedEndDate,
          'user_pilot': this.userPilotFilter,
          'user_in_charge': this.userInChargeFilter,
          'priority': this.priorityFilter,
          'rate_progress_start': rate_progress_start,
          'rate_progress_end': rate_progress_end,
        }).then(data => {
          if (data) {
            if (type === 'all') {
              this.actionPlanList = data['hydra:member']
              this.totalItemsAP = data['hydra:totalItems']
            } else if (type === 'attach') {
              this.actionPlanListAttach = data['hydra:member']
              this.actionPlanListAttach = this.actionPlanListAttach.filter((elem) =>
                  this.actionPlanList.every(ele => elem.id !== ele.id)
              );
              this.totalItemsAPAttach = data['hydra:totalItems']
            } else {
              this.actionPlanList = data['hydra:member']
              this.totalItemsAP = data['hydra:totalItems']
              this.actionPlanListAttach = data['hydra:member']
              this.actionPlanListAttach = this.actionPlanListAttach.filter((elem) =>
                  this.actionPlanList.every(ele => elem.id !== ele.id)
              );
            }
          }
          this.loadingAP = false
          this.loadingAPAttach = false
        })
      } else {
        this.loadingAPAttach = false
      }
    },
    openModalAP() {
      this.PADialog = true
      this.planAction = {}
      this.valueSubDomain = ''
      this.priority = ''
      this.start_date = ''
      this.end_date = ''
      this.revised_end_date = ''
      this.apPilot = ''
      this.responsibleAP = ''
      this.verification_date = ''
      this.typeProgress = {id: 1, value: 'Par le chargé du plan d\'actions'}
      this.rateProgress = {id: 1, value: 0, label: '0%'}
    },
    modalEditPAEval(data) {
      this.PAEvalEditDialog = true
      this.planAction = {}
      this.valueSubDomain = ''
      this.priority = ''
      this.start_date = ''
      this.end_date = ''
      this.revised_end_date = ''
      this.apPilot = ''
      this.responsibleAP = ''
      this.verification_date = ''
      this.PAEditDialog = true
      this.loadingComments = true
      this.loadingTasks = true
      this.getActionPlan(data)
      this.idPA = data.id
    },
    getActionPlan(data) {
      this.planAction = data
      this.loadingTasks = true
      this.loadingComments = true
      this.actionPlanDetails = data
      this.totalItemsCompliancePA = data?.evaluation?.length
      this.totalItemsTasks = data?.action_plan_tasks?.length
      this.totalItemsComments = data?.action_plan_comments?.length
      this.loadingTasks = false
      this.loadingComments = false
      if (data.sub_domain) {
        this.valueSubDomain = {[data.sub_domain.id]: true}
      }
      this.priority = this.valuesPriorities.find(item => item.value == data.priority)
      if (moment(data.start_date).isValid()) {
        this.start_date = new Date(moment(data.start_date).format('yy-MM-DD'))

      }
      if (moment(data.end_date).isValid()) {
        this.end_date = new Date(moment(data.end_date).format('yy-MM-DD'))

      }
      if (moment(data.revised_end_date).isValid()) {
        this.revised_end_date = new Date(moment(data.revised_end_date).format('yy-MM-DD'))

      }
      if (data.user_pilot) {
        this.apPilot = this.listUsers.find(item => item.id == data.user_pilot.id)
      }
      if (data.user_in_charge) {
        this.responsibleAP = this.listUsers.find(item => item.id == data.user_in_charge.id)
      }
      if (data.type_progression) {
        this.typeProgress = this.listTypeProgression.find(item => (item.value).toUpperCase() == (data.type_progression).toUpperCase())
      } else {
        this.typeProgress = {id: 1, value: 'Par le chargé du plan d\'actions'}
      }
      if (data.rate_progress) {
        this.rateProgress = this.listRateProgression.find(item => item.value == data.rate_progress)
      } else {
        this.rateProgress = {id: 1, value: 0, label: '0%'}
      }
      if (data.verification_date !== undefined) {
        if (moment(data.verification_date).isValid()) {
          this.verification_date = new Date(moment(data.verification_date).format('yy-MM-DD'))

        }
      }
      if (data.verifier_user) {
        this.verifier_user = this.listUsers.find(item => item.id == data.verifier_user.id)
      }
    },

    deleteAPEval(data) {
      this.$confirm.require({
        message: this.t('AreYouSureYouWantToDelete'),
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.store.dispatch(Actions.DELETE_EVALUATION_AP, {
            idPA: data.id,
            idEval: this.idEvaluation
          }).then(() => {
            this.toast.add({
              severity: 'success',
              detail: this.t('ComplianceHasSuccessfullyDeleted'),
              life: 3000
            });
            this.getActionPlans(1)
          })
        },
        reject: () => {
        },
        onHide: () => {
        }
      });
    },
    selectNode(selected) {
      if (selected.children?.length) {
        setTimeout(() => {
          this.valueSubDomain = {[selected.id]: false}
        }, 100);
      }
    },
    changeEndDate(event) {
      this.revised_end_date = event
    },
    hideDialogEditPAEval() {
      this.PAEvalEditDialog = false
    },
    savePAEdit() {
      this.submittedPA = true
      if (moment(this.start_date).isValid()) {
        this.planAction.start_date = moment(this.start_date).format()
      }
      if (moment(this.end_date).isValid()) {
        this.planAction.end_date = moment(this.end_date).format()
      }
      if (moment(this.revised_end_date).isValid()) {
        this.planAction.revised_end_date = moment(this.revised_end_date).format()
      }
      if (moment(this.verification_date).isValid()) {
        this.planAction.verification_date = moment(this.verification_date).format()
      }
      if (this.responsibleAP) {
        this.planAction.user_in_charge = 'api/users/' + this.responsibleAP.id
      }
      if (this.apPilot) {
        this.planAction.user_pilot = 'api/users/' + this.apPilot.id
      }
      if (this.verifier_user) {
        this.planAction.verifier_user = 'api/users/' + this.verifier_user.id
      }
      this.planAction.site = 'api/sites/' + this.id_site
      if (this.valueSubDomain) {
        this.planAction.sub_domain = '/api/categories/' + Object.keys(this.valueSubDomain)
      }
      if (this.priority) {
        this.planAction.priority = this.priority.value
      }
      if (this.rateProgress) {
        this.planAction.rate_progress = this.rateProgress.value
      }
      if (this.typeProgress) {
        this.planAction.type_progression = this.typeProgress.value
      }

      this.planAction.updated_by = 'api/users/' + this.user_id
      this.planAction.created_by = this.planAction.created_by ? 'api/users/' + this.planAction.created_by.id : null
      delete this.planAction.site;
      delete this.planAction.evaluation;
      delete this.planAction.action_plan_tasks;
      delete this.planAction.action_plan_comments;
      let actionPlan = this.planAction
      if (this.planAction.title && this.start_date && this.end_date && this.revised_end_date && this.responsibleAP) {
        this.store.dispatch(Actions.PUT_ACTION_PLAN, actionPlan).then(() => {
          this.toast.add({
            severity: 'success',
            detail: this.t('actionPlanHasSuccessfullyAdded'),
            life: 3000
          });
          this.PAEvalEditDialog = false
          this.onPageActionPlan(1)
          this.getDetailsEvaluation(this.idEvaluation);
          this.submittedPA = false
        })
      }
    },
    filterDataTableAttach(event) {
      this.titleFilter = event?.filters?.title?.value
      this.startDateFilter = event?.filters?.start_date?.value
      this.endDateFilter = event?.filters?.end_date?.value
      this.revisedEndDateFilter = event?.filters?.revised_end_date?.value
      this.userPilotFilter = event?.filters?.user_pilot?.value
      this.userInChargeFilter = event?.filters?.user_in_charge?.value
      this.rateProgressFilter = event?.filters?.rate_progress?.value
      if (event?.filters?.priority?.value === "red") {
        this.priorityFilter = '80'
      } else if (event?.filters?.priority?.value === "green") {
        this.priorityFilter = '79'
      } else if (event?.filters?.priority?.value === "yellow") {
        this.priorityFilter = '78'
      }
      this.getActionPlansInit(1, 'attach')
    },
    hideDialogAttachPA() {
      this.attachPADialog = false
    },
    saveAttachPA() {
      this.disableAttachPA = true
      let selectedAPAttach = []
      this.selectedAPAttach.forEach(element => {
        selectedAPAttach.push('api/action_plans/' + element.id)
      })
      this.store.dispatch(Actions.PUT_EVALUATION, {
        id: this.idEvaluation,
        action_plans: selectedAPAttach
      }).then(() => {
        // this.getActionPlans(1)
        this.onPageActionPlan(this.nbPageActionPlan)
        this.attachPADialog = false
        this.selectedAPAttach = []
        this.toast.add({
          severity: 'success',
          detail: this.t('actionPlanHasSuccessfullyAdded'),
          life: 3000
        });
        this.disableAttachPA = false
      })
    },
    hideDialogPA() {
      this.PADialog = false
      this.submittedPA = false
    },
    savePA() {
      this.submittedPA = true
      if (moment(this.start_date).isValid()) {
        this.planAction.start_date = moment(this.startDate).format()
      }
      if (moment(this.end_date).isValid()) {
        this.planAction.end_date = moment(this.end_date).format()
      }
      if (moment(this.revised_end_date).isValid()) {
        this.planAction.revised_end_date = moment(this.revised_end_date).format()
      }
      if (this.responsibleAP) {
        this.planAction.user_in_charge = 'api/users/' + this.responsibleAP.id
      }
      if (this.apPilot) {
        this.planAction.user_pilot = 'api/users/' + this.apPilot.id
      }
      this.planAction.evaluation = ['api/evaluations/' + this.idEvaluation]
      this.planAction.site = 'api/sites/' + this.id_site
      if (this.valueSubDomain) {
        this.planAction.sub_domain = '/api/categories/' + Object.keys(this.valueSubDomain)
      }
      if (this.priority) {
        this.planAction.priority = this.priority.value
      }
      if (this.rateProgress) {
        this.planAction.rate_progress = this.rateProgress.value
      }
      if (this.typeProgress) {
        this.planAction.type_progression = this.typeProgress.value
      }
      this.planAction.created_by = 'api/users/' + this.user_id
      if (this.planAction.title && this.planAction.start_date && this.planAction.end_date && this.planAction.revised_end_date && this.responsibleAP) {
        this.store.dispatch(Actions.ADD_ACTION_PLAN, this.planAction).then(() => {
          this.toast.add({
            severity: 'success',
            detail: this.t('actionPlanHasSuccessfullyAdded'),
            life: 3000
          });
          this.onPageActionPlan(this.nbPageActionPlan)
          this.PADialog = false
        })
      }
    },
    getUsersAP() {
      this.store.dispatch(Actions.GET_USERS_AP, {site_id: this.id_site, 'customHeader': false,}).then((data) => {
        // this.listUsers = data
        this.listUsers = data.filter(item => item.hide_in_pa !== 1)

      })
    },
    onPageActionPlanAttach(page) {
      this.nbPageActionPlanAttach = page
      this.getActionPlansInit(page, 'attach');
    },
    getCurrentSites() {
      this.listSiteByEntity = []
      let exist = false
      let objectCurrentSite = []
      this.treeSelectNodes = []
      return this.store.dispatch(Actions.GET_CUSTOMERS_SITE_TREE, {'customHeader': false}).then(data => {
        this.listSitesValid = true
        var formatSelectTree = (obj) => {
          obj?.forEach((element) => {
            if (Object.prototype.hasOwnProperty.call(element, 'childrens')) {
              formatSelectTree(element.childrens)
            }
            Object.assign(element, {"children": element.childrens})
            if (Object.prototype.hasOwnProperty.call(element, 'site_name')) {
              Object.assign(element, {"label": element.site_name})
              element.id = element.site_id
            } else {
              Object.assign(element, {"label": element.name})
            }
            Object.assign(element, {"icon": 'pi pi-fw pi-inbox'})
            Object.assign(element, {"key": element.id})
          });
          return obj;
        };
        this.treeSelectNodes = formatSelectTree(data);

        function getObjectCurrentSite(arr, id_site) {
          arr?.forEach((obj) => {
            if (obj.childrens && !exist) {
              getObjectCurrentSite(obj.childrens, id_site, obj);
            }
            if (obj.childrens && obj.id == id_site) {
              exist = true
              objectCurrentSite = obj
              return objectCurrentSite
            }

          });
          return objectCurrentSite
        }

        objectCurrentSite = getObjectCurrentSite(this.treeSelectNodes, this.id_site)
        const getSitesTree = (objectCurrentSite) => {
          if (objectCurrentSite.childrens) {
            objectCurrentSite.childrens.forEach(element => {
              if (element.entity_type === 'site') {
                this.listSiteByEntity.push(element.id);
              } else {
                getSitesTree(element);
              }
            });
          }
        };
        getSitesTree(objectCurrentSite);
      })
    },
    getUserSiteRole() {
      this.store.dispatch(Actions.GET_ROLE_USER_SITE, {
        user_id: this.user_id,
        site_id: this.site_id_in_modal
      }).then((data) => {
        this.role_user_site = data ? data.user_role : data
      })
    },
 
      filterDataTableEvent(event){
        this.titleDescription_filter = event.filters?.titleDescription?.value
        this.title_rte_filter = event.filters?.title_rte?.value
        this.title_compliance_filter = event.filters?.title_compliance?.value
        this.evaluation_status_filter = event.filters?.evaluation_status?.value
        this.comment_filter = event.filters?.comment?.value
        this.evaluation_date_filter = event.filters?.evaluation_date?.value
        this.nb_actions_plan_filter = event.filters?.nb_actions_plan?.value
        this.site_names_filter = event.filters?.site_names?.value
        this.titleTotalNumber = event.filteredValue?.length > 1 ? event.filteredValue?.length +' ' +this.t('evaluations') : event.filteredValue?.length+ ' ' + this.t('evaluation');
      },
    initFilters1() {
      this.filters1 = {
        'titleDescription': {value: null, matchMode: FilterMatchMode.CONTAINS},
        'title_rte': {value: null, matchMode: FilterMatchMode.CONTAINS},
        'title_compliance': {value: null, matchMode: FilterMatchMode.CONTAINS},
        'evaluation_status': {value: null, matchMode: FilterMatchMode.CONTAINS},
        'comment': {value: null, matchMode: FilterMatchMode.CONTAINS},
        'evaluation_date': {value: null, matchMode: FilterMatchMode.BETWEEN},
        'nb_actions_plan': {value: null, matchMode: FilterMatchMode.CONTAINS},
        'site_names': {value: null, matchMode: FilterMatchMode.CONTAINS},
      }
    },
    clearFilter1() {
      this.initFilters1();
    },

    handleFilterApplied(event) {
      if (event.evaluation_date?.value) {
        this.evaluation_date_filter = event.evaluation_date?.value
        if (!this.evaluation_date_filter[1]) {
          event.evaluation_date.value = [
            this.evaluation_date_filter[0],
            this.addEndOfDayTime(moment(this.evaluation_date_filter[0]).format('yy-MM-DD'))
          ];

        }
      }
    },
    addEndOfDayTime(dateString) {
      const endDate = new Date(dateString);
      // Réglage de l'heure à 23:59:59
      endDate.setHours(23);
      endDate.setMinutes(59);
      endDate.setSeconds(59);
      return endDate;
    },
    replaceAllColorsWithWhite(html) {
      if (!html) return '';
      return html.replace(/color: rgb\(\d{1,3}, \d{1,3}, \d{1,3}\);/g, 'color: white;');
    },
  }
}
</script>

<style scoped>

</style>